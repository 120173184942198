import React from "react";
import styled from "styled-components";
import { typography, TypographyProps } from "styled-system";
import Container from "./components/Container";
import { BLUE_COLOR, ORANGE_COLOR } from "./style";

export interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <FooterStyled fontSize={1}>
      <Container>
        <span>
          © {new Date().getFullYear()} Edvinas Pranka. All rights reserved.
        </span>
      </Container>
    </FooterStyled>
  );
};

const FooterStyled = styled.div<TypographyProps>`
  position: relative;
  padding: 20px 0;
  background: ${BLUE_COLOR};
  color: white;
  font-weight: 600;
  ${typography}

  @media screen and (min-width: 832px) {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background: transparent;

    span {
      position: relative;
      display: block;
      padding: 10px 20px 10px 30px;
      background: ${BLUE_COLOR};

      &::before {
        position: absolute;
        display: block;
        content: "";
        background: ${ORANGE_COLOR};
        width: 10px;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
`;

export default Footer;
