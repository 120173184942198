import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

let prevLocation: ReturnType<typeof useLocation> = null;

const useRoutes = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    let currentLocation = location;
    return () => {
      (window as any).firstLoad = false;
      prevLocation = currentLocation;
    };
  }, [location]);
};

export const getPrevLocation = () => {
  return prevLocation;
};

export const isFirstLoad = () => {
  return (window as any).firstLoad;
};

export default useRoutes;
