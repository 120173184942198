const dataLab = [
  {
    name: "Sound Code",
    slug: "soundcode",
    description: "Create the orchestra sound from the code or any text",
    live: "https://soundcode.vercel.app/",
    github: "https://github.com/epranka/soundcode",
    technologies: ["react.js", "styled-components", "tone.js"],
  },
  {
    name: "Which runway to choose?",
    slug: "runway",
    live: "https://runway.airportdb.io/",
    description: "Easy way to find the best runway for takeoff or land",
    github: "https://github.com/epranka/runway-app",
    technologies: ["react.js", "tailwindcss", "node.js"],
  },
  {
    name: "Descent calculator",
    slug: "descent-app",
    description: "Aircraft descent calculator",
    live: "https://descent.vercel.app/",
    github: "https://github.com/epranka/descent-app",
    technologies: ["react.js", "styled-components", "cleave.js"],
  },
  {
    name: "Fullscreen color application",
    slug: "f11",
    description: "Select the background and make it fullscreen",
    live: "https://f11.vercel.app/",
    github: "https://github.com/epranka/f11-app",
    technologies: ["react.js", "styled-components"],
  },
  {
    name: "Binary to decimal calculator",
    slug: "bin2dec",
    description:
      "Simple Bin2Dec converter which helps to convert the number from binary to decimal and vica verse",
    live: "https://bin2dec.vercel.app/",
    github: "https://github.com/epranka/bin2dec",
    technologies: ["react.js", "cleave.js"],
  },
  {
    name: "Create package",
    slug: "create-package",
    description: "Ultimate starter kit for JS, JSX, TS, TSX modules",
    github: "https://github.com/epranka/create-package",
    technologies: ["node.js", "semantic-release"],
  },
];

export default dataLab;
