import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import useRoutes from "./hooks/useRoutes";

export interface RoutesWrapperProps {}

const RoutesWrapper: React.FC<RoutesWrapperProps> = (props) => {
  useRoutes();

  const location = useLocation();

  useLayoutEffect(() => {
    if (location.pathname === "/contacts") {
      const $contacts = document.getElementById("contacts") as HTMLDivElement;
      if ($contacts) {
        $contacts.scrollIntoView();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RoutesWrapperStyled>{props.children}</RoutesWrapperStyled>;
};

const RoutesWrapperStyled = styled.div`
  position: relative;
`;

export default RoutesWrapper;
