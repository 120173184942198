import { gsap } from "gsap";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from "styled-system";
import Container from "../components/Container";
import dataPrimaryProjects from "../data/dataProjects";
import { getPrevLocation } from "../hooks/useRoutes";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";

export interface OpenProjectProps {}

const OpenProject: React.FC<OpenProjectProps> = (props) => {
  const projectRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();
  const titleRef = useRef<HTMLDivElement>();
  const descriptionRef = useRef<HTMLDivElement>();
  const titleLineRef = useRef<HTMLDivElement>();
  const technologiesRef = useRef<HTMLDivElement>();
  const designRef = useRef<HTMLDivElement>();
  const reworkRef = useRef<HTMLDivElement>();
  const routeMatch = useRouteMatch<{ slug: string }>();
  const history = useHistory();
  const slug = routeMatch?.params.slug;

  const fillStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    loop: true,
    config: {
      duration: 1000,
    },
  });

  const project = useMemo(() => {
    if (!slug) return null;
    return dataPrimaryProjects.find((project) => project.slug === slug);
  }, [slug]);

  const handleBack = useCallback(() => {
    const prevLocation = getPrevLocation();
    if (prevLocation) {
      history.goBack();
    } else {
      history.push("/", { scroll: "projects" });
    }
  }, [history]);

  useLayoutEffect(() => {
    if (!project) return;
    const $project = projectRef.current!;
    const projectScrollTop = $project.offsetTop;
    window.scrollTo({
      top: projectScrollTop - (window.innerWidth > 640 ? 20 : 0),
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const $header = headerRef.current!;
    const $title = titleRef.current!;
    const $titleLine = titleLineRef.current!;
    const $description = descriptionRef.current!;
    const $design = designRef.current!;
    const $technologies = technologiesRef.current!;
    const $rework = reworkRef.current!;
    const tl = gsap.timeline();
    tl.fromTo(
      $header,
      { x: -10, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 }
    );
    tl.fromTo($title, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0.1);
    tl.to(
      $titleLine,
      { scaleY: 1, transformOrigin: "top", duration: 0.5 },
      0.1
    );
    tl.to($description, { opacity: 1, duration: 0.3 }, 0.2);
    if ($design) {
      tl.to($design, { opacity: 1, duration: 0.35 }, 0.225);
    }
    if ($rework) {
      tl.to($rework, { opacity: 1, duration: 0.4 }, 0.225);
    }
    tl.to($technologies, { opacity: 1, duration: 0.4 }, 0.25);
  }, []);

  if (!project) return <Redirect to="/" />;

  return (
    <OpenProjectStyled ref={projectRef}>
      <OpenProjectBody m={[0, "20px"]} pt={["30px", 5, 6]}>
        <CloseCrossButton onClick={handleBack}>
          <span>Back to projects</span>
        </CloseCrossButton>
        <Container>
          <OpenProjectContent>
            <ProjectHeader ref={headerRef} mb={["40px"]}>
              Project
            </ProjectHeader>
            <ProjectTitleContainer>
              <ProjectTitle
                ref={titleRef}
                fontSize={[5, 7, "92px", "110px"]}
                mb={["30px"]}
                pl={["20px", "30px"]}
              >
                <div>
                  <ProjectTitleLine ref={titleLineRef} />
                  {project.live ? (
                    <a href={project.live} target="_blank" rel="noreferrer">
                      {project.name}
                    </a>
                  ) : (
                    project.name
                  )}
                </div>
              </ProjectTitle>
            </ProjectTitleContainer>
            <ProjectDescription
              ref={descriptionRef}
              fontSize={[1, 2]}
              mb={["30px"]}
              maxWidth={["320px", "640px"]}
            >
              {project.description}
            </ProjectDescription>
            {project.design ? (
              <ProjectDesign ref={designRef} mb={["30px"]}>
                Design by{" "}
                <a href={project.design.link} target="_blank" rel="noreferrer">
                  {project.design.name}
                </a>
              </ProjectDesign>
            ) : null}
            {project.rework ? (
              <ReworkContainer ref={reworkRef} mb={["30px"]}>
                <ReworkCircle>
                  <ReworkFill style={fillStyle} />
                </ReworkCircle>
                <Rework>Rework in progress</Rework>
              </ReworkContainer>
            ) : null}
            <ProjectTechnologies ref={technologiesRef} mb={["50px", "100px"]}>
              {project.technologies.map((technology, key) => {
                return <Technology key={key}>{technology}</Technology>;
              })}
            </ProjectTechnologies>
            <ProjectMediaGrid>
              {project.images.map((image, key) => {
                return (
                  <ImageContainer key={key} mb={["50px", "100px"]}>
                    <Image src={image} />
                  </ImageContainer>
                );
              })}
            </ProjectMediaGrid>
          </OpenProjectContent>
        </Container>
        <BackToProjectsButton onClick={handleBack}>
          Back to projects
        </BackToProjectsButton>
      </OpenProjectBody>
    </OpenProjectStyled>
  );
};

const OpenProjectStyled = styled.div`
  position: relative;
`;

const OpenProjectBody = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border-top: 10px solid ${BLUE_COLOR};
  border-left: 10px solid ${BLUE_COLOR};
  border-bottom: 10px solid ${BLUE_COLOR};
  border-right: 10px solid ${BLUE_COLOR};
  background: rgba(255, 255, 255, 0.95);
  ${space}
`;

const CloseCrossButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  display: none;
  width: 100px;
  height: 40px;

  span {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    white-space: nowrap;
    align-items: center;
    font-weight: 600;
    color: ${BLUE_COLOR};
    transform: translateX(-20px);
    padding-right: 10px;
    font-size: 20px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    width: 40px;
    height: 5px;
    background: ${BLUE_COLOR};
    transform: rotate(45deg);
    opacity: 1;
    transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    width: 40px;
    height: 5px;
    background: ${BLUE_COLOR};
    transform: rotate(-45deg);
    opacity: 1;
    transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  }

  &:hover {
    span {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
    }

    &:after {
      opacity: 0;
      transform: rotate(90deg);
    }

    &:before {
      opacity: 0;
      transform: rotate(-90deg);
    }
  }

  @media screen and (min-width: 640px) {
    display: block;
    right: 40px;
  }

  @media screen and (min-width: 992px) {
    top: 40px;
    right: 60px;
  }
`;

const BackToProjectsButton = styled.div`
  color: ${BLUE_COLOR};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  ${typography}

  @media screen and (min-width: 640px) {
    height: 100px;
  }

  transition: color 0.25s ease-in-out, background 0.25s ease-in-out;

  &:hover {
    color: white;
    background: ${BLUE_COLOR};
  }
`;

const OpenProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ProjectHeader = styled.div<SpaceProps>`
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  color: ${BLUE_COLOR};
  opacity: 0;
  ${space}
`;

const ProjectTitleContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProjectTitle = styled.h2<TypographyProps & SpaceProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  color: ${BLUE_COLOR};
  opacity: 0;
  ${typography}
  ${space}

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const ProjectTitleLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  transform: scaleY(0);
  transform-origin: top;
  background: ${ORANGE_COLOR};
`;

const ProjectDescription = styled.p<TypographyProps & SpaceProps & LayoutProps>`
  display: block;
  margin: 0;
  color: #696969;
  line-height: 1.5;
  opacity: 0;
  ${typography}
  ${space}
  ${layout}
`;

const ProjectDesign = styled.div<SpaceProps>`
  ${space}
  text-transform: uppercase;
  font-size: 14px;
  color: #696969;
  opacity: 0;

  a {
    margin-left: 10px;
    font-weight: bold;
    text-decoration: none;
    color: #525252;
  }
`;

const ReworkContainer = styled.div<SpaceProps>`
  display: flex;
  align-items: center;
  ${space};
  opacity: 0;
`;

const Rework = styled.div<SpaceProps>`
  color: ${ORANGE_COLOR};
  font-weight: 600;
  ${space}
`;

const ReworkCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${ORANGE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ReworkFill = styled(animated.div)`
  width: 12px;
  height: 12px;
  border-radius: inherit;
  background: ${ORANGE_COLOR};
`;

const ProjectTechnologies = styled.div<SpaceProps>`
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  ${space}
`;

const Technology = styled.div`
  padding-right: 10px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const ProjectMediaGrid = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div<SpaceProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${space}
`;

const Image = styled.img`
  max-width: 100%;
  border: 1px solid #e5e5e5;
`;

export default OpenProject;
