import createStore from "zustand";
import Canvas from "./Canvas";

export type CanvasStoreType = {
  canvas: Canvas | null;
};

const useCanvasStore = createStore<CanvasStoreType>((set) => {
  return {
    canvas: null,
  };
});

export default useCanvasStore;
