import React from "react";
import { ScrollControllerContextType } from "./types";

const ScrollControllerContext =
  React.createContext<ScrollControllerContextType>({
    hooks: new Map(),
    update: () => {},
    setComponent: () => {},
  });

export default ScrollControllerContext;
