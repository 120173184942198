import React, { useCallback, useState } from "react";
import styled from "styled-components";
import useAnimated from "../Animated/useAnimated";
import useMouseMove from "../hooks/useMouseMove";
import scrolled from "../ScrollController/scrolled";
import { BLUE_COLOR } from "../style";
import { mapRange } from "../utils/math";

export interface LandingPageBackgroundProps {}

const MOUSE_MIN_T = 24;
const MOUSE_MAX_T = 26;
const MOUSE_INITIAL_T = mapRange(0.5, -1, 1, MOUSE_MIN_T, MOUSE_MAX_T);
const TRIGGER_OFFSET = 50;

const LandingPageBackground: React.FC<LandingPageBackgroundProps> = (props) => {
  const [followScroll, setFollowScroll] = useState(false);

  const mouse = useMouseMove();
  const mouseX = mapRange(mouse.x, -1, 1, MOUSE_MIN_T, MOUSE_MAX_T);
  const scrollY = mapRange(
    window.scrollY,
    TRIGGER_OFFSET,
    window.innerHeight,
    MOUSE_INITIAL_T,
    50
  );

  const { translate } = useAnimated(
    {
      translate: followScroll ? scrollY : mouseX,
    },
    { factor: 0.1 }
  );

  const handleAction = useCallback(() => {
    setFollowScroll(true);
  }, []);

  const handleRevert = useCallback(() => {
    setFollowScroll(false);
  }, []);

  return (
    <LandingPageBackgroundStyled
      action={handleAction}
      offset={TRIGGER_OFFSET}
      revert={handleRevert}
    >
      <DeveloperSVGContainer style={{ transform: `translateX(${translate}%)` }}>
        <DeveloperSVG />
      </DeveloperSVGContainer>
      <DeveloperSVGContainer
        style={{ transform: `translateX(-${translate}%)` }}
      >
        <DeveloperSVG />
      </DeveloperSVGContainer>
    </LandingPageBackgroundStyled>
  );
};

const LandingPageBackgroundStyled = styled(scrolled.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  z-index: -1;

  svg {
    width: auto;
    height: 100%;
  }
`;

const DeveloperSVGContainer = styled.div`
  height: 50%;
`;

const DeveloperSVG = () => {
  return (
    <svg viewBox="0 0 80 19">
      <defs>
        <style type="text/css">
          @import
          url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");
        </style>
      </defs>
      <text
        y="13"
        fill={BLUE_COLOR}
        style={{ fontFamily: "Ubuntu", fontWeight: 600, opacity: 0.04 }}
      >
        Developer
      </text>
    </svg>
  );
};

export default LandingPageBackground;
