import React from "react";
import styled from "styled-components";
import Container from "../components/Container";
import HeroSection from "./HeroSection";
import LandingPageBackground from "./LandingPageBackground";

export interface LandingPageProps {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <LandingPageStyled>
      <Container>
        <HeroSection />
      </Container>
      <LandingPageBackground />
    </LandingPageStyled>
  );
};

export const LandingPageStyled = styled.section`
  position: relative;
  height: 100vh;
`;

export default LandingPage;
