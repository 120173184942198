import React, { useCallback, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { BLUE_COLOR } from "../style";

export interface HeaderProps {
  white?: boolean;
}

export interface HeaderHandler {
  enter(tl: GSAPTimeline, afterTime?: number);
  exit(tl: GSAPTimeline, afterTime?: number);
}

const Header = React.forwardRef<any, React.PropsWithChildren<HeaderProps>>(
  (props, ref) => {
    const headerRef = useRef<HTMLHeadingElement>();
    const topLineRef = useRef<HTMLDivElement>();

    const handleEnter = useCallback(
      (tl: GSAPTimeline, afterTime: number = 0.25) => {
        const $header = headerRef.current!;
        const $topLine = topLineRef.current!;
        tl.to($header, { opacity: 1, duration: 0.2 }, 0.25);
        tl.to(
          $topLine,
          { scaleX: 1, transformOrigin: "left", duration: 0.3 },
          afterTime
        );
      },
      []
    );

    const handleExit = useCallback(
      (tl: GSAPTimeline, afterTime: number = 0.25) => {
        const $header = headerRef.current!;
        const $topLine = topLineRef.current!;
        tl.to($header, { opacity: 0, duration: 0.2 }, 0.25);
        tl.to(
          $topLine,
          { scaleX: 0, transformOrigin: "left", duration: 0.3 },
          afterTime
        );
      },
      []
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          enter: handleEnter,
          exit: handleExit,
        };
      },
      [handleEnter, handleExit]
    );

    return (
      <>
        <HeaderStyled ref={headerRef} white={props.white} mb={["20px"]}>
          {props.children}
        </HeaderStyled>
        <HeaderLine
          ref={topLineRef}
          white={props.white}
          mb={["20px", "50px"]}
        />
      </>
    );
  }
);

const HeaderStyled = styled.h2<SpaceProps & { white?: boolean }>`
  display: inline-block;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => (props.white ? "white" : BLUE_COLOR)};
  opacity: 0;
  ${space}
`;

const HeaderLine = styled.div<SpaceProps & { white?: boolean }>`
  width: 100%;
  height: 10px;
  background: ${(props) => (props.white ? "white" : BLUE_COLOR)};
  margin-bottom: 20px;
  transform: scaleX(0);
  transform-origin: left;
  ${space}
`;

export default Header;
