import * as THREE from "three";
import { Clock, MeshLambertMaterial } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { mapRange } from "../utils/math";
import { isMesh } from "../utils/threeJsUtils";
import AbstractScene from "./AbstractScene";
import Canvas from "./Canvas";

type PyramidMeshData = {
  position: [number, number, number];
  color: [number, number, number];
};

class HelloScene extends AbstractScene {
  stickman: GLTF | undefined;
  stickmanMixer: THREE.AnimationMixer | undefined;
  animations: Record<string, THREE.AnimationAction> = {};
  rootGroup: THREE.Group;
  pyramid!: THREE.InstancedMesh<THREE.BoxGeometry, THREE.MeshLambertMaterial>;
  clock: THREE.Clock;

  constructor() {
    super();
    this.clock = new Clock(false);
    this.rootGroup = new THREE.Group();
    this.scene.add(this.rootGroup);

    this.camera.position.set(0, 22, 2);
    this.camera.rotation.set(0, 0, 0);

    this.init();
  }

  async init() {
    this.createLights();
    this.createPyramid();
    await this.loadStickMan();
    this.rootGroup.add(this.pyramid);
    this.rootGroup.add(this.stickman!.scene);
    const climbAnimation = this.animations["hello"];
    if (climbAnimation) climbAnimation.play();
    this.clock.start();
  }

  createLights() {
    const ambientLight = new THREE.AmbientLight();
    ambientLight.intensity = 1.3;
    const pointLight = new THREE.PointLight();
    pointLight.intensity = 3.5;
    pointLight.position.set(0, 90, 15);
    this.scene.add(ambientLight, pointLight);
  }

  createPyramid() {
    const tmpMatrix4 = new THREE.Matrix4();
    const meshesData = this.createMeshesData();
    const colors = this.createColorsArray(meshesData);
    this.pyramid = this.createMesh(meshesData.length, colors);
    this.scene.add(this.pyramid);
    this.pyramid.setMatrixAt(0, tmpMatrix4);
    for (let i = 0; i < meshesData.length; i++) {
      const meshData = meshesData[i];
      tmpMatrix4.setPosition(...meshData.position);
      this.pyramid.setMatrixAt(i, tmpMatrix4);
    }
    this.pyramid.rotation.set(0, Math.PI / 4, 0);
  }

  createMeshesData() {
    const data: PyramidMeshData[] = [];
    for (let level = 0; level < PYRAMID_LEVELS; level++) {
      const offset = level * PYRAMID_LEVEL_OFFSET;
      // Left side
      for (
        let z = 0;
        z < Math.min(PYRAMID_LEVELS - level, PYRAMID_MAX_SIDE);
        z++
      ) {
        data.push({
          position: [z / 2 + offset, level, -z],
          color:
            (z + level) % 4 !== 0
              ? [1000, 127, 0].map((c) => c / 1000)
              : ([72, 72, 255].map((c) => c / 1000) as any),
        });
      }
      // Root and right side
      for (
        let z = 0;
        z < Math.min(PYRAMID_LEVELS - level, PYRAMID_MAX_SIDE);
        z++
      ) {
        data.push({
          position: [z / 2 + offset, level, z],
          color:
            (z + level) % 4 !== 0
              ? [1000, 127, 0].map((c) => c / 1000)
              : ([72, 72, 255].map((c) => c / 1000) as any),
        });
      }
    }
    return data;
  }

  createColorsArray(meshes: PyramidMeshData[]) {
    return Float32Array.from(
      meshes.flatMap((mesh) => {
        return mesh.color;
      })
    );
  }

  createMesh(count: number, colors: ArrayLike<number>) {
    const geometry = new THREE.BoxBufferGeometry(1, 1, 1);
    const material = new MeshLambertMaterial({ vertexColors: true });
    const colorBufferAttribute = new THREE.InstancedBufferAttribute(colors, 3);
    geometry.setAttribute("color", colorBufferAttribute);
    const mesh = new THREE.InstancedMesh(geometry, material, count);
    return mesh;
  }

  async loadStickMan() {
    this.stickman = await this.loadObject("/obj/hello.glb");
    this.stickman.scene.rotation.y = Math.PI + Math.PI / 4 + Math.PI / 2;
    const offsetRotation = Math.PI / 4;
    this.stickman.scene.position.set(
      Math.cos(offsetRotation) * 10.5,
      21.5,
      Math.sin(offsetRotation) * -10.5
    );
    // Replace material to support opacity
    this.stickman.scene.traverse((object) => {
      if (isMesh(object)) {
        object.material = new THREE.MeshLambertMaterial({
          color: new THREE.Color(
            0.0006070435047149658,
            0.04091523960232735,
            0.7991029620170593
          ),
        });
      }
    });
    this.scene.add(this.stickman.scene);
    this.createStickManAnimation();
  }

  createStickManAnimation() {
    const stickman = this.stickman!;
    this.stickmanMixer = new THREE.AnimationMixer(stickman.scene);
    this.animations = this.getAnimationClips(stickman, this.stickmanMixer);
  }

  animate(ctx: Canvas, delta: number) {
    // Handle camera
    let x = 0;
    let y = 23;
    let z = 2;
    const highAspect = 1.55; // Full HD
    const lowAspect = 0.5; // 320x640
    const aspect = this.width / this.height;
    x = mapRange(aspect, lowAspect, highAspect, 5.5, x);
    y = mapRange(aspect, lowAspect, highAspect, 25, y);
    this.camera.position.set(x, y, z);

    const targetY = mapRange(this.mouseX, -1, 1, -0.05, 0.05);
    const targetX = mapRange(this.mouseY, -1, 1, -0.01, 0.01);
    this.camera.rotation.y += (targetY - this.camera.rotation.y) * 0.005;
    this.camera.rotation.x += (targetX - this.camera.rotation.x) * 0.01;

    // // Handle scene animation
    if (this.stickmanMixer) {
      this.stickmanMixer?.update(delta);
    }
  }
}

const PYRAMID_LEVELS = 22;
const PYRAMID_LEVEL_OFFSET = 0.5;
const PYRAMID_MAX_SIDE = 20;

export default HelloScene;
