import styled from "styled-components";
import { BLUE_COLOR } from "../style";

export const WhiteBackground = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  top: 20px;
  border-top: 10px solid ${BLUE_COLOR};
  border-left: 10px solid ${BLUE_COLOR};
  border-bottom: 10px solid ${BLUE_COLOR};
  border-right: 10px solid ${BLUE_COLOR};
  transform: scaleX(0);
  transform-origin: right;
  background: rgba(255, 255, 255, 0.95);

  @media screen and (max-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
