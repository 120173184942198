import { gsap } from "gsap";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";
import Container from "../components/Container";
import useSecondEffect from "../hooks/useSecondEffect";
import useWindowSize from "../hooks/useWindowSize";
import scrolled from "../ScrollController/scrolled";
import useUpdateScroll from "../ScrollController/useUpdateScroll";
import { BLUE_COLOR } from "../style";

export interface CitationPageProps {}

const PADDING_SIZE = 20;

const CitationPage: React.FC<CitationPageProps> = (props) => {
  const projectsRef = useRef<HTMLDivElement>();
  const blueBackgroundRef = useRef<HTMLDivElement>();
  const citationRef = useRef<HTMLParagraphElement>();
  const citationAuthorLineRef = useRef<HTMLDivElement>();
  const citationAuthorRef = useRef<HTMLDivElement>();
  const windowSize = useWindowSize();

  const handleEnter = useCallback(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const $citation = citationRef.current!;
    const $citationAuthor = citationAuthorRef.current!;
    const $citationAuthorLine = citationAuthorLineRef.current!;
    const width = window.innerWidth;
    const tl = gsap.timeline();
    if (width >= 768) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      tl.to($blueBackground, {
        scaleX,
        scaleY,
        duration: 0.5,
        transformOrigin: "center",
      });
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: PADDING_SIZE,
        bottom: PADDING_SIZE,
        left: PADDING_SIZE,
        right: PADDING_SIZE,
      });
    } else {
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    tl.fromTo(
      $citation,
      { opacity: 0, y: 5 },
      { opacity: 1, y: 0, duration: 0.5 },
      0.25
    );
    tl.to($citationAuthor, { opacity: 1, duration: 0.3 }, 0.25);
    tl.to(
      $citationAuthorLine,
      { scaleX: 1, transformOrigin: "left", duration: 0.5 },
      0.25
    );
  }, []);

  const handleExit = useCallback(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const $citation = citationRef.current!;
    const $citationAuthor = citationAuthorRef.current!;
    const $citationAuthorLine = citationAuthorLineRef.current!;
    const tl = gsap.timeline();
    const width = window.innerWidth;
    if (width >= 768) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      tl.set($blueBackground, {
        scaleX,
        scaleY,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
      tl.to($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        duration: 0.5,
        transformOrigin: "center",
      });
      tl.set($blueBackground, {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    } else {
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    tl.to($citation, { opacity: 0, duration: 0.2 }, 0.25);
    tl.to($citationAuthor, { opacity: 0, duration: 0.2 }, 0.25);
    tl.to(
      $citationAuthorLine,
      { scaleX: 0, transformOrigin: "left", duration: 0.2 },
      0.25
    );
  }, []);

  const updateScroll = useUpdateScroll();

  useSecondEffect(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const width = windowSize.width;
    if (width >= 768) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      gsap.set($blueBackground, {
        scaleX,
        scaleY,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    } else {
      gsap.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    updateScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  return (
    <CitationPageStyled
      ref={projectsRef}
      action={handleEnter}
      revert={handleExit}
      offset={-window.innerHeight * 0.6}
      viewport="top"
      direction="down"
      py={["80px", "120px", "120px", "220px"]}
    >
      <BlueBackground ref={blueBackgroundRef}></BlueBackground>
      <Container>
        <CitationContainer>
          <Citation ref={citationRef} fontSize={[4, 5, 5, 7]} mb={[32]}>
            "It's kind of fun to do the impossible."
          </Citation>
        </CitationContainer>
        <CitationAuthorContainer>
          <CitationAuthorLine ref={citationAuthorLineRef}></CitationAuthorLine>
          <CitationAuthor ref={citationAuthorRef}>
            Walt Disney (Co-Founder, Walt Disney Company)
          </CitationAuthor>
        </CitationAuthorContainer>
      </Container>
    </CitationPageStyled>
  );
};

const CitationPageStyled = styled(scrolled.section)<SpaceProps>`
  position: relative;
  ${space}
`;

const CitationContainer = styled.div<SpaceProps>`
  position: relative;
  display: flex;
  color: white;
  font-weight: 600;
  ${space}
`;

const CitationAuthorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const CitationAuthorLine = styled.div`
  position: absolute;
  width: 25px;
  height: 2px;
  background: white;
  transform: scaleX(0);
  transform-origin: left;
`;

const CitationAuthor = styled.div<TypographyProps>`
  color: white;
  font-weight: 600;
  padding-left: 40px;
  opacity: 0;
  font-family: monospace;
  ${typography}
`;

const Citation = styled.p<TypographyProps & SpaceProps>`
  display: block;
  opacity: 0;
  ${space}
  ${typography}
`;

const BlueBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${BLUE_COLOR};
`;

export default CitationPage;
