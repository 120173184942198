import { useMemo, useState } from "react";
import useAnimationFrame from "use-animation-frame";
import useMounted from "../hooks/useMounted";

type UseAnimatedPropsLiteral = number;
type UseAnimatedPropsExtended = {
  value: number;
  factor?: number;
};

type UseAnimatedProp = UseAnimatedPropsLiteral | UseAnimatedPropsExtended;

type UseAnimatedParams = Record<string, UseAnimatedProp>;

type UseAnimatedConfig = {
  factor?: number;
};

type Numerize<P extends Record<string, any>> = {
  [K in keyof P]: number;
};

type AnimatedValues<P> = {
  readonly [K in keyof P]: number;
};

const defaultConfig = {
  factor: 0.05,
};

const round = (val: number, decimals: number) => {
  return Math.round(val * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

const useAnimated = <P extends UseAnimatedParams>(
  params: P,
  config: UseAnimatedConfig = defaultConfig
) => {
  const mounted = useMounted();

  const defaultFactor = config.factor ?? defaultConfig.factor;
  const defaultValues = useMemo(() => {
    return Object.keys(params).reduce((result, propName) => {
      const prop = params[propName];
      if (typeof prop === "number") {
        result[propName] = prop;
      } else {
        result[propName] = prop.value;
      }
      return result;
    }, {}) as Numerize<P>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [animatedValues, setAnimatedValues] =
    useState<Numerize<P>>(defaultValues);

  useAnimationFrame(() => {
    if (!mounted) return;
    setAnimatedValues((animatedValues) => {
      return Object.keys(params).reduce(
        (result, propName) => {
          const prop = params[propName];
          const currentValue = round(result[propName], 2);
          const targetValue = round(
            typeof prop === "number" ? prop : prop.value,
            2
          );
          const factor =
            typeof prop === "number"
              ? defaultFactor
              : prop.factor ?? defaultFactor;
          result[propName] += (targetValue - currentValue) * factor;
          return result;
        },
        { ...animatedValues } as Record<string, any>
      ) as Numerize<P>;
    });
  }, [params, mounted]);

  return animatedValues as AnimatedValues<P>;
};

export default useAnimated;
