import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import useAnimated from "../Animated/useAnimated";
import useMouseMove from "../hooks/useMouseMove";
import scrolled from "../ScrollController/scrolled";
import { BLUE_COLOR } from "../style";
import { mapRange } from "../utils/math";

export interface AboutPageBackgroundProps {}

const MOUSE_MIN_T = 24;
const MOUSE_MAX_T = 26;

const AboutPageBackground: React.FC<AboutPageBackgroundProps> = (props) => {
  const backgroundRef = useRef<HTMLDivElement>();
  const mouse = useMouseMove();
  const mouseX = mapRange(mouse.x, -1, 1, MOUSE_MIN_T, MOUSE_MAX_T);

  const { translate } = useAnimated(
    {
      translate: mouseX,
    },
    { factor: 0.1 }
  );

  const handleAction = useCallback(() => {
    const $bg = backgroundRef.current!;
    $bg.style["opacity"] = "1";
  }, []);

  const handleRevert = useCallback(() => {
    const $bg = backgroundRef.current!;
    $bg.style["opacity"] = "0";
  }, []);

  return (
    <AboutPageBackgroundStyled
      ref={backgroundRef}
      action={handleAction}
      revert={handleRevert}
      offset={-window.innerHeight * 0.2}
      viewport="top"
    >
      <SVGContainer style={{ transform: `translateX(${translate}%)` }}>
        <SVG />
      </SVGContainer>
      <SVGContainer style={{ transform: `translateX(-${translate}%)` }}>
        <SVG />
      </SVGContainer>
    </AboutPageBackgroundStyled>
  );
};

const AboutPageBackgroundStyled = styled(scrolled.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;

  svg {
    width: auto;
    height: 100%;
  }
`;

const SVGContainer = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
`;

const SVG = () => {
  return (
    <svg viewBox="0 0 80 19">
      <defs>
        <style type="text/css">
          @import
          url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");
        </style>
      </defs>
      <text
        y="13"
        fill={BLUE_COLOR}
        style={{ fontFamily: "Ubuntu", fontWeight: 600, opacity: 0.03 }}
      >
        About me
      </text>
    </svg>
  );
};

export default AboutPageBackground;
