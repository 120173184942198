const dataPrimaryProjects = [
  {
    name: "AirportDB.io",
    slug: "airportdb.io",
    live: "https://airportdb.io",
    images: [
      "/images/projects/airportdb-screen-1.jpg",
      "/images/projects/airportdb-screen-2.jpg",
      "/images/projects/airportdb-screen-3.jpg",
      "/images/projects/airportdb-screen-4.jpg",
    ],
    description:
      "The free airport database API service, that provides about 60k airports with locations, runways, frequencies, navaids, and so on in the JSON format. The free airport database API service, that provides about 60k airports with locations, runways, frequencies, navaids, and so on in the JSON format.",
    technologies: ["react.js", "tailwindcss", "nest.js", "node.js", "mongodb"],
  },
  {
    name: "Gintautas Rapalis",
    slug: "gintautasrapalis",
    design: {
      name: "Nikodemas Pajuodis",
      link: "https://www.behance.net/NiCCodemus",
    },
    images: [
      "/images/projects/gintautasrapalis-screen-1.jpg",
      "/images/projects/gintautasrapalis-screen-2.jpg",
      "/images/projects/gintautasrapalis-screen-3.jpg",
    ],
    description:
      "Photographer website. The front-end created using the Next.js framework based on React.js. Effects and animations created using WebGL and Pixi.js. Customized headless WordPress for the backend. The whole system is dockerized (Docker) and runs on the Linux server.",
    technologies: [
      "react.js",
      "next.js",
      "styled-components",
      "pixi",
      "glsl",
      "node.js",
      "wordpress",
      "php",
      "mysql",
      "docker",
    ],
  },
  {
    name: "Gerlangas",
    slug: "gerlangas",
    design: {
      name: "Nikodemas Pajuodis",
      link: "https://www.behance.net/NiCCodemus",
    },
    live: "https://gerlangas.lt",
    images: [
      "/images/projects/gerlangas-screen-1.jpg",
      "/images/projects/gerlangas-screen-2.jpg",
      "/images/projects/gerlangas-screen-3.jpg",
      "/images/projects/gerlangas-screen-4.jpg",
    ],
    description:
      "Website for the PVC windows manufacturing company. ERN stack: Express.js, React.js, Node.js. Instead of MongoDB, this project was used WordPress as a proxied backend and data binding via API. Database MySQL.",
    technologies: [
      "react.js",
      "next.js",
      "styled-components",
      "three.js",
      "node.js",
      "wordpress",
      "php",
      "mysql",
      "docker",
    ],
  },
  {
    name: "Kodmina",
    slug: "kodmina",
    design: {
      name: "Nikodemas Pajuodis",
      link: "https://www.behance.net/NiCCodemus",
    },
    live: "https://www.kodmina.lt",
    images: [
      "/images/projects/kodmina-screen-1.jpg",
      "/images/projects/kodmina-screen-2.jpg",
      "/images/projects/kodmina-screen-3.jpg",
      "/images/projects/kodmina-screen-4.jpg",
      "/images/projects/kodmina-screen-5.jpg",
    ],
    description:
      "My business company representation website. The background uses the Three.js and GLSL custom shaders to render the dynamic flying particles and it's transformations.",
    technologies: [
      "react.js",
      "styled-components",
      "glsl",
      "three.js",
      "webgl",
      "mongodb",
      "node.js",
    ],
  },
  {
    name: "Tekstai TV",
    slug: "tekstai-tv",
    live: "http://tekstai-tv.lt",
    rework: true,
    design: {
      name: "Nikodemas Pajuodis",
      link: "https://www.behance.net/NiCCodemus",
    },
    images: [
      "/images/projects/tekstai-tv-screen-1.jpg",
      "/images/projects/tekstai-tv-screen-2.jpg",
      "/images/projects/tekstai-tv-screen-3.jpg",
      "/images/projects/tekstai-tv-screen-4.jpg",
      "/images/projects/tekstai-tv-screen-5.jpg",
    ],
    description:
      "Yearbook of Lithuanian Literature. This is the largest Lithuanian archive of literature and writers. For this project have been created the custom advanced content management system.",
    technologies: ["blaze", "node.js", "meteor.js", "mongodb", "less"],
  },
  {
    name: "Šratas",
    slug: "sratas",
    live: "https://sratas.lt",
    rework: true,
    images: [
      "/images/projects/sratas-screen-1.jpg",
      "/images/projects/sratas-screen-2.jpg",
      "/images/projects/sratas-screen-3.jpg",
    ],
    description:
      "The social network for the airsoft enthusiasts and game organizers. For the entire system was used the Laravel MVC framework based on PHP. Database MySQL.",
    technologies: ["laravel", "php", "mysql", "jquery", "less"],
  },
  {
    name: "Priedaimobiliems",
    slug: "priedaimobiliems",
    live: "https://priedaimobiliems.lt",
    images: [
      "/images/projects/priedaimobiliems-screen-1.jpg",
      "/images/projects/priedaimobiliems-screen-2.jpg",
      "/images/projects/priedaimobiliems-screen-3.jpg",
    ],
    description:
      "Enhancement of the smartphones accessories eshop. Custom cart, products management functionality. The fuzzy search filter. A lot of additional custom-created modules to improve the eshop management performance.",
    technologies: ["prestashop", "php", "mysql"],
  },
];

export default dataPrimaryProjects;
