export const isMesh = (object: any): object is THREE.Mesh => {
  return object.type?.endsWith("Mesh");
};

export const getFirstMaterial = <T extends THREE.Material>(
  object: THREE.Mesh
): T => {
  if (Array.isArray(object.material)) return object.material[0] as T;
  else return object.material as T;
};
