import React, { useCallback } from "react";
import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";
import Container from "../components/Container";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";

export interface ProjectLineProps {
  project: { name: string; slug: string };
  onClick: (slug: string) => any;
}

const ProjectLine: React.FC<ProjectLineProps> = (props) => {
  const { project } = props;

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      props.onClick(project.slug);
    },
    [project.slug, props]
  );

  return (
    <ProjectLineStyled className="project-line">
      <ProjectLink href={`/project/${project.slug}`} onClick={handleClick}>
        <Background>
          <BackgroundLine />
        </Background>
        <Container>
          <ProjectName fontSize={[4, 5, 6, "72px"]} py={["30px", "50px"]}>
            {project.name}
          </ProjectName>
        </Container>
      </ProjectLink>
    </ProjectLineStyled>
  );
};

const Background = styled.div`
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.25s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;

  @media screen and (min-width: 768px) {
    left: 20px;
    right: 20px;
  }

  @media screen and (min-width: 992px) {
    left: 40px;
    right: 40px;
  }
`;

const ProjectName = styled.div<TypographyProps & SpaceProps>`
  ${typography}
  ${space}
  color: white;
  transition: color 0.25s ease-in-out;
`;

const BackgroundLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background: ${ORANGE_COLOR};
  transition: transform 0.5s ease-in-out;
  transform: scaleY(0);
  transform-origin: top;
`;

const ProjectLink = styled.a`
  color: inherit;
  display: block;
  height: 100%;
  text-decoration: none;
`;

const ProjectLineStyled = styled.li<SpaceProps>`
  display: block;
  position: relative;
  font-weight: 700;
  cursor: pointer;
  opacity: 0;
  ${space}

  &:hover ${Background} {
    transform: scaleY(1);
  }

  &:hover ${BackgroundLine} {
    transform: scaleY(1);
  }

  &:hover ${ProjectName} {
    color: ${BLUE_COLOR};
  }
`;

export default ProjectLine;
