import { gsap } from "gsap";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { typography, TypographyProps } from "styled-system";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import useAnimated from "../Animated/useAnimated";
import dataPrimaryProjects from "../data/dataProjects";
import useSecondEffect from "../hooks/useSecondEffect";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";

export interface PreloaderProps {
  onLoad: () => any;
}

const imagesToLoad = dataPrimaryProjects.reduce(
  (result, project) => {
    result.push(...project.images);
    return result;
  },
  ["/images/cartoon-no-gb-smaller.png"]
);

const glbToLoad = ["/obj/climb.glb", "/obj/hello.glb"];

const gltfLoader = new GLTFLoader();

const Preloader: React.FC<PreloaderProps> = (props) => {
  const [targetPercent, setTargetPercent] = useState(0.94);
  const preloaderRef = useRef<HTMLDivElement>();

  const onLoaded = useCallback(() => {
    document.body.style["overflow"] = "initial";
    const $preloader = preloaderRef.current!;
    props.onLoad();
    gsap.to($preloader, {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        $preloader.remove();
      },
    });
  }, [props]);

  const preload = useCallback(() => {
    const totalResources = imagesToLoad.length + glbToLoad.length;
    let loadedResources = 0;
    for (let i = 0; i < imagesToLoad.length; i++) {
      const img = document.createElement("img");
      img.src = imagesToLoad[i];
      img.style.display = "none";
      // eslint-disable-next-line no-loop-func
      img.onload = function () {
        loadedResources++;
        const percent = (loadedResources / totalResources) * 100;
        setTargetPercent(Math.round(percent * 100) / 100);
        img.remove();
      };
      img.onerror = function () {
        console.error("Error to load: " + img.src);
      };
      document.body.appendChild(img);
    }
    for (let i = 0; i < glbToLoad.length; i++) {
      // eslint-disable-next-line no-loop-func
      gltfLoader.load(glbToLoad[i], () => {
        loadedResources++;
        const percent = (loadedResources / totalResources) * 100;
        setTargetPercent(Math.round(percent * 100) / 100);
      });
    }
  }, []);

  const { percent } = useAnimated({ percent: targetPercent });

  useLayoutEffect(() => {
    document.body.style["overflow"] = "hidden";

    preload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roundedPercent = Math.round(percent * 100) / 100;

  useSecondEffect(() => {
    if (roundedPercent === 100) {
      onLoaded();
    }
  }, [roundedPercent]);

  const percentString = roundedPercent.toFixed(2) + " %";

  return (
    <PreloaderStyled ref={preloaderRef}>
      <PreloaderContainer>
        <Name fontSize={[2, 3, 4]}>Edvinas Pranka</Name>
        <Split />
        <Progress fontSize={[2, 3, 4]}>{percentString}</Progress>
      </PreloaderContainer>
    </PreloaderStyled>
  );
};

const PreloaderStyled = styled.div`
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreloaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 100px;
`;

const Name = styled.div<TypographyProps>`
  color: ${BLUE_COLOR};
  font-weight: 600;
  ${typography}
`;

const Split = styled.div`
  width: 4px;
  height: 30px;
  background: ${ORANGE_COLOR};
  margin: 0 10px;
`;

const Progress = styled.div<TypographyProps>`
  color: ${ORANGE_COLOR};
  font-weight: 600;
  ${typography}
`;

export default Preloader;
