const dataTechnologies = [
  {
    name: "Frontend",
    technologies: [
      { name: "react.js", favorite: true },
      { name: "next.js", favorite: true },
      { name: "styled-components", favorite: true },
      { name: "redux", favorite: true },
      "typescript",
      "three.js",
      "webgl",
      "glsl",
      "css",
      "less",
      "scss",
      "stylus",
      "bootstrap",
      "tailwindcss",
    ],
  },
  {
    name: "Backend",
    technologies: [
      { name: "node.js", favorite: true },
      { name: "express.js", favorite: true },
      { name: "mongodb", favorite: true },
      "mysql",
      "graphql",
      "php",
      "python",
    ],
  },
  {
    name: "Other",
    technologies: [
      { name: "nginx", favorite: true },
      { name: "ubuntu", favorite: true },
      { name: "git", favorite: true },
      "webpack",
      "docker",
      "jenkins",
      "apache",
    ],
  },
];

export default dataTechnologies;
