import { useCallback } from "react";

function useCombinedRef(...refs: any[]) {
  return useCallback((current) => {
    for (const ref of refs) {
      if (ref == null) continue;
      if (typeof ref === "function") {
        ref(current);
      } else {
        ref.current = current;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}

export default useCombinedRef;
