import React from "react";
import styled from "styled-components";
import { typography, TypographyProps } from "styled-system";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";

export interface LabItemProps {
  lab: {
    name: string;
    slug: string;
    description: string;
    live?: string;
    github: string;
    technologies: string[];
  };
}

const LabItem: React.FC<LabItemProps> = (props) => {
  return (
    <LabItemStyled className="lab-item">
      <LabItemCard>
        <LabItemLine />
        <LabItemBlueLine />
        <LabItemTitle
          fontSize={["20px", "26px"]}
          href={props.lab.live || props.lab.github}
          target="_blank"
          rel="noreferrer"
        >
          {props.lab.name}
        </LabItemTitle>
        <LabItemTechnologies>
          {props.lab.technologies.map((technology, key) => {
            return (
              <LabItemTechnology key={key}>{technology}</LabItemTechnology>
            );
          })}
        </LabItemTechnologies>
        <LabItemDescription>{props.lab.description}</LabItemDescription>
        <LabItemGit>
          <LabItemGitContent
            href={props.lab.github}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/git-icon-black.svg"
              alt={props.lab.name + " git"}
            />
            GIT
          </LabItemGitContent>
        </LabItemGit>
      </LabItemCard>
    </LabItemStyled>
  );
};

const LabItemStyled = styled.li`
  display: block;
  position: relative;
  flex-basis: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
  opacity: 0;

  @media screen and (min-width: 1440px) {
    flex-basis: 50%;
  }
`;

const LabItemTitle = styled.a<TypographyProps>`
  position: relative;
  color: ${BLUE_COLOR};
  font-weight: bold;
  margin-bottom: 20px;
  text-decoration: none;
  ${typography}
`;

const LabItemDescription = styled.p`
  display: block;
  color: #696969;
  line-height: 1.5;
  flex-grow: 1;
`;

const LabItemTechnologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const LabItemTechnology = styled.div`
  padding-right: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  color: black;
`;

const LabItemLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  background: ${ORANGE_COLOR};
`;

const LabItemBlueLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
  background: ${BLUE_COLOR};
`;

const LabItemGit = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LabItemGitContent = styled.a`
  width: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  color: inherit;
  font-size: 14px;
  margin-top: 20px;
  letter-spacing: 1px;
  color: black;

  img {
    width: 18px;
    height: auto;
    margin-right: 5px;
  }
`;

const LabItemCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 30px 30px 20px 40px;
  background: white;
  border: 1px solid #e6e6e6;

  &:hover ${LabItemBlueLine} {
    transform: scaleY(1);
  }

  @media screen and (min-width: 640px) {
    background: rgba(255, 255, 255, 0.5);
  }
`;

export default LabItem;
