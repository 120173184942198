import TWEEN, { Tween } from "@tweenjs/tween.js";
import * as THREE from "three";
import { mapRange } from "../utils/math";
import AbstractScene from "./AbstractScene";

class Canvas {
  $container: HTMLDivElement;
  renderer: THREE.WebGLRenderer | undefined;
  width: number;
  height: number;
  mouseX: number;
  mouseY: number;
  camera: THREE.OrthographicCamera | undefined;
  scenesMap: Record<string, AbstractScene> = {};
  currentScene: AbstractScene | null = null;
  disposed: boolean = false;
  clock: THREE.Clock;
  currentTween: Tween<{ opacity: number }> | null = null;
  tweenInProgress: boolean = false;

  constructor($container: HTMLDivElement) {
    this.$container = $container;

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.mouseX = 0;
    this.mouseY = 0;

    this.clock = new THREE.Clock();

    this.createRenderer();

    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.render = this.render.bind(this);

    this.bindEvents();
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      powerPreference: "high-performance",
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFShadowMap;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setSize(this.width, this.height);
    this.$container.appendChild(this.renderer.domElement);
  }

  addScene(name: string, scene: AbstractScene) {
    this.scenesMap[name] = scene;
    scene.width = this.width;
    scene.height = this.height;
  }

  enterScene(name: string) {
    if (this.currentScene) {
      // Scenes transition
      const nextScene = this.scenesMap[name];
      if (this.currentTween) {
        // Tween in progress, stop it
        this.currentTween.stop();
      }
      const fadeInState = { opacity: 0 };
      const fadeInTween = new TWEEN.Tween(fadeInState)
        .to({ opacity: nextScene.opacity }, 200)
        .onStart(() => {
          nextScene.currentOpacity = 0;
          this.currentScene = nextScene;
          this.currentScene.restore();
        })
        .onUpdate(() => {
          nextScene!.currentOpacity = fadeInState.opacity;
        });
      const fadeOutState = { opacity: this.currentScene?.currentOpacity };
      const fadeOutTween = new TWEEN.Tween(fadeOutState)
        .to({ opacity: 0 }, 200)
        .onUpdate(() => {
          this.currentScene!.currentOpacity = fadeOutState.opacity;
        });
      this.currentTween = fadeOutTween.chain(fadeInTween);
      this.currentTween.onComplete(() => {
        this.currentTween = null;
      });
      this.currentTween.start();
    } else {
      // Entering new scene
      const state = { opacity: 0 };
      this.currentScene = this.scenesMap[name];
      this.currentScene.restore();
      this.currentScene.currentOpacity = 0;
      this.currentTween = new TWEEN.Tween(state)
        .to({ opacity: this.currentScene.opacity }, 200)
        .onUpdate(() => {
          this.currentScene!.currentOpacity = state.opacity;
        })
        .onComplete(() => {
          this.currentTween = null;
        })
        .start();
    }

    // this.currentScene = this.scenesMap[name];
  }

  render(time?) {
    if (this.disposed) return;
    TWEEN.update(time);
    requestAnimationFrame(this.render);
    if (this.currentScene) {
      this.currentScene.width = this.width;
      this.currentScene.height = this.height;
    }
    if (this.currentScene) this.currentScene.render(this);
  }

  handleWindowResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer?.setSize(this.width, this.height);
  }

  handleVisibilityChange() {
    if (document.visibilityState === "visible" && this.currentScene) {
      this.currentScene.restore();
    }
  }

  handleMouseMove(e) {
    this.mouseX = mapRange(e.clientX, 0, window.innerWidth, -1, 1);
    this.mouseY = mapRange(e.clientY, 0, window.innerHeight, -1, 1);
    if (this.currentScene) {
      this.currentScene.mouseX = this.mouseX;
      this.currentScene.mouseY = this.mouseY;
    }
  }

  bindEvents() {
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    document.addEventListener("mousemove", this.handleMouseMove);
  }

  dispose() {
    this.disposed = true;
    window.removeEventListener("resize", this.handleWindowResize);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    document.removeEventListener("mousemove", this.handleMouseMove);
    this.renderer?.dispose();
    this.renderer?.domElement.remove();
  }
}

export default Canvas;
