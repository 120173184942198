import { gsap } from "gsap";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import Container from "../components/Container";
import Header, { HeaderHandler } from "../components/Header";
import dataLab from "../data/dataLab";
import scrolled from "../ScrollController/scrolled";
import LabItem from "./LabItem";
import LabPageBackground from "./LabPageBackground";

export interface LabPageProps {}

const LabPage: React.FC<LabPageProps> = (props) => {
  const itemsListRef = useRef<HTMLUListElement>();
  const headerRef = useRef<HeaderHandler>();

  const handleEnter = useCallback(() => {
    const $itemsList = itemsListRef.current!;
    const $items = Array.from<HTMLDivElement>(
      $itemsList.querySelectorAll(".lab-item")
    );
    const tl = gsap.timeline();
    tl.fromTo(
      $items,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.05, duration: 0.3 }
    );
    headerRef.current.enter(tl);
  }, []);

  const handleExit = useCallback(() => {
    const $itemsList = itemsListRef.current!;
    const $items = Array.from<HTMLDivElement>(
      $itemsList.querySelectorAll(".lab-item")
    );
    const tl = gsap.timeline();
    tl.to($items, { opacity: 0, duration: 0.3 }, 0.3);
    headerRef.current.exit(tl);
  }, []);

  return (
    <LabPageStyled
      action={handleEnter}
      revert={handleExit}
      offset={-window.innerHeight * 0.6}
      viewport="top"
      direction="down"
      py={["80px", "160px"]}
    >
      <LabPageBackground />
      <Container shrink halfLeft>
        <Header ref={headerRef}>Laboratory</Header>
      </Container>
      <Container halfLeft>
        <LabItemsList ref={itemsListRef}>
          {dataLab.map((lab, key) => {
            return <LabItem key={key} lab={lab} />;
          })}
        </LabItemsList>
      </Container>
    </LabPageStyled>
  );
};

const LabPageStyled = styled(scrolled.section)<SpaceProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${space}
`;

const LabItemsList = styled.ul`
  display: block;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
`;

export default LabPage;
