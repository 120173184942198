import { gsap } from "gsap";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import useCanvas from "../Canvas/hooks/useCanvas";
import Container from "../components/Container";
import Header, { HeaderHandler } from "../components/Header";
import dataAboutMe from "../data/dataAboutMe";
import scrolled from "../ScrollController/scrolled";
import { ORANGE_COLOR } from "../style";
import AboutPageBackground from "./AboutPageBackground";

export interface AboutPageProps {}

const AboutPage: React.FC<AboutPageProps> = (props) => {
  const paragraphsContainerRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HeaderHandler>();
  const canvas = useCanvas();

  const handleEnter = useCallback(() => {
    if (canvas) {
      canvas.enterScene("hello");
    }
    const $paragraphsContainer = paragraphsContainerRef.current!;
    const $paragraphs = Array.from<HTMLParagraphElement>(
      $paragraphsContainer.querySelectorAll(".paragraph")
    );
    const $paragraphsLines = Array.from<HTMLParagraphElement>(
      $paragraphsContainer.querySelectorAll(".paragraph-line")
    );
    const tl = gsap.timeline();
    tl.fromTo(
      $paragraphs,
      { opacity: 0, y: 5 },
      { opacity: 1, y: 0, stagger: 0.05, duration: 0.3 },
      0.2
    );
    tl.to(
      $paragraphsLines,
      { scaleY: 1, transformOrigin: "top", stagger: 0.05, duration: 0.3 },
      0.3
    );
    headerRef.current.enter(tl);
  }, [canvas]);

  const handleExit = useCallback(() => {
    if (canvas) {
      canvas.enterScene("climb");
    }
    const $paragraphsContainer = paragraphsContainerRef.current!;
    const $paragraphs = Array.from<HTMLParagraphElement>(
      $paragraphsContainer.querySelectorAll(".paragraph")
    );
    const $paragraphsLines = Array.from<HTMLParagraphElement>(
      $paragraphsContainer.querySelectorAll(".paragraph-line")
    );
    const tl = gsap.timeline();
    tl.to($paragraphs, { opacity: 0, duration: 0.3 }, 0.25);
    tl.to(
      $paragraphsLines,
      { scaleY: 0, transformOrigin: "top", duration: 0.3 },
      0.25
    );
    headerRef.current.exit(tl);
  }, [canvas]);

  return (
    <AboutPageStyled
      action={handleEnter}
      revert={handleExit}
      offset={-window.innerHeight * 0.6}
      viewport="top"
      direction="down"
      py={["80px", "160px"]}
    >
      <AboutPageBackground />
      <Container shrink halfLeft>
        <Header ref={headerRef}>About me</Header>
      </Container>
      <Container>
        <ParagraphsContainer ref={paragraphsContainerRef}>
          {dataAboutMe.map((text, key) => {
            return (
              <Paragraph key={key} className="paragraph">
                <ParagraphLine className="paragraph-line" />
                {text}
              </Paragraph>
            );
          })}
        </ParagraphsContainer>
      </Container>
    </AboutPageStyled>
  );
};

const AboutPageStyled = styled(scrolled.section)<SpaceProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${space}
`;

const ParagraphsContainer = styled.div``;

const Paragraph = styled.p`
  position: relative;
  display: block;
  color: #696969;
  margin-bottom: 40px;
  line-height: 1.5;
  padding-left: 30px;
  text-align: justify;
  opacity: 0;
  background: rgba(255, 255, 255, 0.95);
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  @media screen and (min-width: 640px) {
    max-width: 640px;
  }

  @media screen and (min-width: 1200px) {
    background: transparent;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const ParagraphLine = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: ${ORANGE_COLOR};
  transform: scaleY(0);
  transform-origin: top;
`;

export default AboutPage;
