export const cssMapRange = (
  input0: string,
  input1: string,
  output0: string,
  output1: string
) => {
  return `calc(${output0} + (${parseFloat(output1)} - ${parseFloat(
    output0
  )}) * ((100vw - ${input0}) / (${parseFloat(input1)} - ${parseFloat(
    input0
  )})))`;
};
