import { gsap } from "gsap";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";
import Container from "../components/Container";
import Header, { HeaderHandler } from "../components/Header";
import dataContacts from "../data/dataContacts";
import scrolled from "../ScrollController/scrolled";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";
import ContactPageBackground from "./ContactPageBackground";

export interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = (props) => {
  const descriptionRef = useRef<HTMLParagraphElement>();
  const contactListRef = useRef<HTMLUListElement>();
  const headerRef = useRef<HeaderHandler>();

  const handleEnter = useCallback(() => {
    const $description = descriptionRef.current!;
    const $contactList = contactListRef.current!;
    const $contactItems = Array.from<HTMLLIElement>(
      $contactList.querySelectorAll(".item")
    );
    const $contactItemsLines = Array.from<HTMLLIElement>(
      $contactList.querySelectorAll(".item-line")
    );
    const tl = gsap.timeline();
    tl.to($description, { opacity: 1, duration: 0.3 }, 0.25);
    tl.to($contactItems, { opacity: 1, stagger: 0.05, duration: 0.3 }, 0.25);
    tl.to(
      $contactItemsLines,
      {
        scaleY: 1,
        transformOrigin: "top",
        stagger: 0.05,
        duration: 0.25,
      },
      0.25
    );
    headerRef.current.enter(tl);
  }, []);

  const handleExit = useCallback(() => {
    const $description = descriptionRef.current!;
    const $contactList = contactListRef.current!;
    const $contactItems = Array.from<HTMLLIElement>(
      $contactList.querySelectorAll(".item")
    );
    const $contactItemsLines = Array.from<HTMLLIElement>(
      $contactList.querySelectorAll(".item-line")
    );
    const tl = gsap.timeline();
    tl.to($description, { opacity: 0, duration: 0.3 }, 0.25);
    tl.to($contactItems, { opacity: 0 }, 0.25);
    tl.to(
      $contactItemsLines,
      {
        scaleY: 0,
        transformOrigin: "top",
        duration: 0.25,
      },
      0.25
    );
    headerRef.current.exit(tl);
  }, []);

  return (
    <ContactPageStyled
      id="contacts"
      action={handleEnter}
      revert={handleExit}
      offset={-window.innerHeight * 0.6}
      viewport="top"
      direction="down"
      py={["80px", "160px"]}
    >
      <ContactPageBackground />
      <Container shrink halfLeft>
        <Header ref={headerRef}>Get in touch</Header>
      </Container>
      <Container>
        <Description
          ref={descriptionRef}
          fontSize={["22px"]}
          mb={["40px", "60px", "80px"]}
        >
          I am here to discuss your idea or issue :)
        </Description>
        <ContactList ref={contactListRef}>
          {dataContacts.map((item, key) => {
            return (
              <ContactItem
                key={key}
                className="item"
                mb={["50px"]}
                // mr={[0, 0, "40px", "110px"]}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <ContactItemLine className="item-line" />
                  <ContactItemBlueLine />
                  <ContactItemHeader mb={["10px"]} fontSize={["24px"]}>
                    {item.label}
                  </ContactItemHeader>
                  <ContactItemLink fontSize={["18px"]}>
                    {item.display}
                  </ContactItemLink>
                </a>
              </ContactItem>
            );
          })}
        </ContactList>
      </Container>
    </ContactPageStyled>
  );
};

const ContactPageStyled = styled(scrolled.section)<SpaceProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${space}
`;

const Description = styled.p<TypographyProps & SpaceProps>`
  position: relative;
  display: block;
  color: #696969;
  line-height: 1.5;
  opacity: 0;
  ${typography}
  ${space}

  @media screen and (min-width: 640px) {
    max-width: 640px;
  }
`;

const ContactItemLine = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: ${ORANGE_COLOR};
  transform: scaleY(0);
  transform-origin: top;
`;

const ContactItemBlueLine = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: ${BLUE_COLOR};
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
`;

const ContactItem = styled.li<TypographyProps & SpaceProps>`
  position: relative;
  padding-left: 20px;
  opacity: 0;
  ${space}

  a {
    text-decoration: none;
  }

  &:hover ${ContactItemBlueLine} {
    transform: scaleY(1);
  }
`;

const ContactList = styled.ul`
  display: block;
  flex-wrap: wrap;

  ${ContactItem} {
    width: 100%;
  }

  @media screen and (min-width: 832px) {
    display: flex;
    max-width: 50%;

    ${ContactItem} {
      width: 50%;
    }
  }

  @media screen and (min-width: 1200px) {
    ${ContactItem} {
      width: calc(100% / 3);
    }
  }
`;

const ContactItemHeader = styled.div<TypographyProps & SpaceProps>`
  color: ${BLUE_COLOR};
  font-weight: bold;
  ${typography}
  ${space}
`;

const ContactItemLink = styled.div<TypographyProps & SpaceProps>`
  color: #696969;
  ${typography}
  ${space}
`;

export default ContactPage;
