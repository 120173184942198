const dataContacts = [
  {
    label: "Telegram",
    link: "https://t.me/epranka",
    display: "t.me/epranka",
  },
  {
    label: "LinkedIn",
    link: "https://www.linkedin.com/in/epranka/",
    display: "in/epranka",
  },
  {
    label: "Email",
    link: "mailto:epranka@gmail.com",
    display: "epranka@gmail.com",
  },
  {
    label: "GitHub",
    link: "https://github.com/epranka",
    display: "github/epranka",
  },
  {
    label: "Twitter",
    link: "https://twitter.com/epranka",
    display: "@epranka",
  },
  {
    label: "Dev.to",
    link: "https://dev.to/epranka",
    display: "dev.to/epranka",
  },
];

export default dataContacts;
