import React from "react";
import styled from "styled-components";
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import Paragraph from "../components/Paragraph";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";
import InProgress from "./InProgress";

export interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  return (
    <HeroSectionStyled pt={["15%", "10%"]}>
      <Image>
        <img src="/images/cartoon-no-gb-smaller.png" alt="Edvinas Pranka" />
      </Image>
      <MainHeader fontSize={[6, 7, 8, "110px"]} mb={[2, 2, 2, 3]}>
        Developer
      </MainHeader>
      <NameStyled mb={[3, 4]} display={["block", "block", "flex"]}>
        <SecondHeader fontSize={[4, 5, 5, 6]}>Edvinas Pranka</SecondHeader>
        <InProgress />
      </NameStyled>
      <Paragraph
        color="#696969"
        maxWidth={["80%", "450px"]}
        fontSize={[1, 1, 2, 3]}
      >
        Hi, I am a senior full-stack developer with over ten years of
        experience. I specialized in websites and web applications development.
        I help the clients to solve their puzzles and realize the ideas. Mostly
        you can find me as{" "}
        <a
          href="/#contacts"
          style={{
            color: ORANGE_COLOR,
            textDecoration: "none",
          }}
        >
          @epranka
        </a>
      </Paragraph>
    </HeroSectionStyled>
  );
};

const HeroSectionStyled = styled.div<SpaceProps>`
  ${space}
`;

const MainHeader = styled.div<TypographyProps & LayoutProps & SpaceProps>`
  font-weight: 700;
  color: ${BLUE_COLOR};
  ${typography}
  ${layout}
  ${space}
`;

const NameStyled = styled.div<LayoutProps & SpaceProps>`
  align-items: center;
  ${layout}
  ${space}
`;

const SecondHeader = styled.h1<TypographyProps & LayoutProps & SpaceProps>`
  margin-right: 20px;
  white-space: nowrap;
  ${typography}
  ${layout}
  ${space}
`;

const Image = styled.div`
  img {
    width: 100px;

    @media screen and (min-width: 1200px) {
      width: 150px;
    }
  }
`;

export default HeroSection;
