import { gsap } from "gsap";
import React, { useCallback, useEffect, useRef } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import Container from "../components/Container";
import Header, { HeaderHandler } from "../components/Header";
import dataPrimaryProjects from "../data/dataProjects";
import { getPrevLocation } from "../hooks/useRoutes";
import useSecondEffect from "../hooks/useSecondEffect";
import useWindowSize from "../hooks/useWindowSize";
import scrolled from "../ScrollController/scrolled";
import useUpdateScroll from "../ScrollController/useUpdateScroll";
import { BLUE_COLOR } from "../style";
import { WhiteBackground } from "./components";
import OpenProject from "./OpenProject";
import ProjectLine from "./ProjectLine";
import ProjectsPageBackground from "./ProjectsPageBackground";

export interface ProjectsPageProps {}

const PADDING_SIZE = 20;

const ProjectsPage: React.FC<ProjectsPageProps> = (props) => {
  const projectsRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HeaderHandler>();
  const blueBackgroundRef = useRef<HTMLDivElement>();
  const whiteBackgroundRef = useRef<HTMLDivElement>();
  const projectsListRef = useRef<HTMLUListElement>();

  const history = useHistory();
  const location = useLocation<any>();

  const windowSize = useWindowSize();

  const handleProjectClick = useCallback(
    async (slug: string) => {
      const $blueBackground = blueBackgroundRef.current!;
      const $whiteBackground = whiteBackgroundRef.current!;
      const $projectsList = projectsListRef.current!;
      const tl = gsap.timeline({
        onComplete: () => {
          history.push("/project/" + slug);
        },
      });
      tl.to($projectsList, { opacity: 0, duration: 0.15 });
      tl.add("bg-change");
      tl.to(
        $blueBackground,
        { scaleX: 0, duration: 0.25, transformOrigin: "left" },
        "bg-change"
      );
      tl.to(
        $whiteBackground,
        { scaleX: 1, duration: 0.25, transformOrigin: "right" },
        "bg-change"
      );
      headerRef.current.exit(tl, 0);
    },
    [history]
  );

  const handleEnter = useCallback(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const $projectList = projectsListRef.current!;
    const $lines = Array.from<HTMLDivElement>(
      $projectList.querySelectorAll(".project-line")
    );
    const width = window.innerWidth;
    const tl = gsap.timeline();
    if (width >= 768 && $blueBackground) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2; // height - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      tl.to($blueBackground, {
        scaleX,
        scaleY,
        duration: 0.5,
        transformOrigin: "center",
      });
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: PADDING_SIZE,
        bottom: PADDING_SIZE,
        left: PADDING_SIZE,
        right: PADDING_SIZE,
      });
    } else {
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    tl.to($lines, { opacity: 1, stagger: 0.05, duration: 0.2 }, 0.1);
    headerRef.current.enter(tl);
  }, []);

  const handleExit = useCallback(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const $projectList = projectsListRef.current!;
    const $lines = Array.from<HTMLDivElement>(
      $projectList.querySelectorAll(".project-line")
    );
    const tl = gsap.timeline();
    const width = window.innerWidth;
    if (width >= 768 && $blueBackground) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      tl.set($blueBackground, {
        scaleX,
        scaleY,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
      tl.to($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        duration: 0.5,
        transformOrigin: "center",
      });
      tl.set($blueBackground, {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    tl.to($lines, { opacity: 0, duration: 0.2 }, 0.3);
    headerRef.current.exit(tl);
  }, []);

  const updateScroll = useUpdateScroll();

  useSecondEffect(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const width = windowSize.width;
    if (width >= 768 && $blueBackground) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      gsap.set($blueBackground, {
        scaleX,
        scaleY,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    } else {
      gsap.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    updateScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  useEffect(() => {
    const prevLocation = getPrevLocation();
    if (prevLocation && prevLocation.pathname.startsWith("/project/")) {
      const $projects = projectsRef.current!;
      if ($projects) {
        const projectScrollTop = $projects.offsetTop;
        window.scrollTo({
          top: projectScrollTop,
        });
      }
    }
    updateScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Switch>
        <Route path="/project/:slug">
          <OpenProject />
        </Route>
        <Route path="/">
          <ProjectsPageStyled
            ref={projectsRef}
            action={handleEnter}
            revert={handleExit}
            offset={-window.innerHeight * 0.6}
            viewport="top"
            direction="down"
            py={["80px", "160px"]}
          >
            <BlueBackground ref={blueBackgroundRef}></BlueBackground>
            <WhiteBackground ref={whiteBackgroundRef}></WhiteBackground>
            <ProjectsPageBackground />
            <Container halfLeft shrink>
              <Header ref={headerRef} white>
                Projects
              </Header>
            </Container>
            <ProjectsList ref={projectsListRef}>
              {dataPrimaryProjects.map((project, key) => {
                return (
                  <ProjectLine
                    key={key}
                    project={project}
                    onClick={handleProjectClick}
                  />
                );
              })}
            </ProjectsList>
          </ProjectsPageStyled>
        </Route>
      </Switch>
    </>
  );
};

const ProjectsPageStyled = styled(scrolled.section)<SpaceProps>`
  position: relative;
  min-height: 100vh;
  ${space}
`;

const BlueBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${BLUE_COLOR};
`;

const ProjectsList = styled.ul`
  display: block;
`;

export default ProjectsPage;
