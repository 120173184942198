import React, {
  ComponentPropsWithRef,
  ComponentRef,
  ElementType,
  ForwardRefExoticComponent,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { v4 as uv4 } from "uuid";
import { domElements, DomElements } from "./domElements";
import ScrollControllerContext from "./ScrollControllerContext";
import { ScrolledComponentProps } from "./types";
import useCombinedRef from "./useCombinedRef";

type ScrolledPrimitives = {
  [Tag in DomElements]: ScrolledComponent<Tag>;
};

type ScrolledComponent<T extends ElementType> = ForwardRefExoticComponent<
  ComponentPropsWithRef<T> & ScrolledComponentProps
>;

const createComponent = <T extends ElementType>(tag: T) => {
  const component = React.forwardRef<
    ComponentRef<T>,
    ComponentPropsWithRef<T> & ScrolledComponentProps
  >((props, ref) => {
    const elementRef = useRef<any>();

    const controllerContext = useContext(ScrollControllerContext);

    const componentId = useMemo(() => {
      return uv4();
    }, []);

    let { viewport, hook, direction, offset, action, revert, ...restProps } =
      props;
    viewport = viewport ?? "top";
    hook = hook ?? "top";
    direction = direction ?? "down";
    offset = offset ?? 0;

    useLayoutEffect(() => {
      controllerContext.setComponent({
        id: componentId,
        element: elementRef,
        viewport,
        hook,
        direction,
        offset,
        action,
        revert,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewport, hook, direction, offset, action, revert]);

    const combinedRef = useCombinedRef(elementRef, ref);

    return React.createElement(tag, { ...restProps, ref: combinedRef });
  });

  component.displayName = `scrolled.${tag}`;

  return component;
};

const scrolled = {} as ScrolledPrimitives;

for (const tag of domElements) {
  scrolled[tag] = createComponent(tag) as any;
}

export default scrolled;
