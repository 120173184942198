import { gsap } from "gsap";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";
import Container from "../components/Container";
import Header, { HeaderHandler } from "../components/Header";
import dataTechnologies from "../data/dataTechnologies";
import useSecondEffect from "../hooks/useSecondEffect";
import useWindowSize from "../hooks/useWindowSize";
import scrolled from "../ScrollController/scrolled";
import useUpdateScroll from "../ScrollController/useUpdateScroll";
import { BLUE_COLOR, ORANGE_COLOR } from "../style";
import TechnologiesPageBackground from "./TechnologiesPageBackground";

export interface TechnologiesPageProps {}

const PADDING_SIZE = 20;

const TechnologiesPage: React.FC<TechnologiesPageProps> = (props) => {
  const projectsRef = useRef<HTMLDivElement>();
  const blueBackgroundRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HeaderHandler>();
  const descriptionRef = useRef<HTMLParagraphElement>();
  const technologiesRef = useRef<HTMLUListElement>();

  const windowSize = useWindowSize();

  const handleEnter = useCallback(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const $description = descriptionRef.current!;
    const $technologies = technologiesRef.current!;
    const $stacks = Array.from<HTMLLIElement>(
      $technologies.querySelectorAll(".stack")
    );
    const width = window.innerWidth;
    const tl = gsap.timeline();
    if (width >= 768) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      tl.to($blueBackground, {
        scaleX,
        scaleY,
        duration: 0.5,
        transformOrigin: "center",
      });
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: PADDING_SIZE,
        bottom: PADDING_SIZE,
        left: PADDING_SIZE,
        right: PADDING_SIZE,
      });
    } else {
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    tl.fromTo(
      $description,
      { opacity: 0, y: 5 },
      { opacity: 1, y: 0, duration: 0.5 },
      0.25
    );
    tl.fromTo(
      $stacks,
      { opacity: 0, y: 5 },
      { opacity: 1, y: 0, stagger: 0.05, duration: 0.5 },
      0.25
    );
    headerRef.current.enter(tl);
  }, []);

  const handleExit = useCallback(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const $description = descriptionRef.current!;
    const $technologies = technologiesRef.current!;
    const $stacks = Array.from<HTMLLIElement>(
      $technologies.querySelectorAll(".stack")
    );
    const tl = gsap.timeline();
    const width = window.innerWidth;
    if (width >= 768) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      tl.set($blueBackground, {
        scaleX,
        scaleY,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
      tl.to($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        duration: 0.5,
        transformOrigin: "center",
      });
      tl.set($blueBackground, {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    } else {
      tl.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    tl.to($stacks, { opacity: 0, duration: 0.5 }, 0.25);
    tl.to($description, { opacity: 0, duration: 0.2 }, 0.25);
    headerRef.current.exit(tl);
  }, []);

  const updateScroll = useUpdateScroll();

  useSecondEffect(() => {
    const $blueBackground = blueBackgroundRef.current!;
    const width = windowSize.width;
    if (width >= 768) {
      const targetWidth = $blueBackground.clientWidth - PADDING_SIZE * 2;
      const targetHeight = $blueBackground.clientHeight - PADDING_SIZE * 2;
      const scaleX = targetWidth / $blueBackground.clientWidth;
      const scaleY = targetHeight / $blueBackground.clientHeight;
      gsap.set($blueBackground, {
        scaleX,
        scaleY,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    } else {
      gsap.set($blueBackground, {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
    updateScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  return (
    <TechnologiesPageStyled
      ref={projectsRef}
      action={handleEnter}
      revert={handleExit}
      offset={-window.innerHeight * 0.6}
      viewport="top"
      direction="down"
      py={["80px", "160px"]}
    >
      <BlueBackground ref={blueBackgroundRef}></BlueBackground>
      <TechnologiesPageBackground />
      <Container halfLeft shrink>
        <Header ref={headerRef} white>
          Technologies
        </Header>
      </Container>
      <Container>
        <Description ref={descriptionRef} mb={["50px"]}>
          The technologies, I work with every day, practice, and have enough
          experience
        </Description>
        <TechnologiesContainer ref={technologiesRef}>
          {dataTechnologies.map((stack, key) => {
            return (
              <Stack key={key} className="stack" pb={["60px"]} pr={[0, "80px"]}>
                <StackHeader fontSize={["20px"]}>{stack.name}</StackHeader>
                <StackTechnologies>
                  {stack.technologies.map((technology, key) => {
                    return (
                      <Technology key={key}>
                        {typeof technology === "string" ? (
                          technology
                        ) : (
                          <>
                            {technology.favorite ? (
                              <img
                                src="/images/five-star-white.svg"
                                alt="favorite technology"
                              />
                            ) : null}
                            {technology.name}
                          </>
                        )}
                      </Technology>
                    );
                  })}
                </StackTechnologies>
              </Stack>
            );
          })}
        </TechnologiesContainer>
      </Container>
    </TechnologiesPageStyled>
  );
};

const TechnologiesPageStyled = styled(scrolled.section)<SpaceProps>`
  position: relative;
  min-height: 80vh;
  ${space}
`;

const BlueBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${BLUE_COLOR};
`;

const Description = styled.p<SpaceProps>`
  color: white;
  font-weight: bold;
  opacity: 0;
  ${space}
`;

const TechnologiesContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const Stack = styled.li<SpaceProps>`
  display: block;
  opacity: 0;
  flex-basis: 100%;
  ${space}

  @media screen and (min-width: 640px) {
    flex-basis: 50%;
    width: 50%;
  }

  @media screen and (min-width: 1200px) {
    flex-basis: auto;
    width: auto;
  }
`;

const StackHeader = styled.h3<TypographyProps>`
  color: ${BLUE_COLOR};
  border-left: 10px solid ${ORANGE_COLOR};
  background: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  ${typography}

  @media screen and (min-width: 1200px) {
    padding: 10px 80px;
  }
`;

const StackTechnologies = styled.ul`
  padding-left: 26px;
`;

const Technology = styled.li`
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 5px 0;
  font-size: 18px;

  img {
    position: absolute;
    width: 18px;
    height: auto;
    transform: translateX(-26px);
  }
`;

export default TechnologiesPage;
