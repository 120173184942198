import React from "react";
import styled from "styled-components";
import { cssMapRange } from "../utils/cssUtils";

export interface ContainerProps {
  fillLeft?: boolean;
  fillRight?: boolean;
  halfLeft?: boolean;
  halfRight?: boolean;
  shrink?: boolean;
}

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <ContainerStyled
      fillLeft={props.fillLeft}
      fillRight={props.fillRight}
      halfLeft={props.halfLeft}
      halfRight={props.halfRight}
      shrink={props.shrink}
    >
      {props.children}
    </ContainerStyled>
  );
};

const ContainerStyled = styled.div<{
  fillLeft?: boolean;
  fillRight?: boolean;
  halfLeft?: boolean;
  halfRight?: boolean;
  shrink?: boolean;
}>`
  position: relative;
  margin: 0 auto;
  width: 100%;
  flex-grow: ${(props) => (props.shrink ? 0 : 1)};
  padding: 0 20px;
  ${(props) => (props.fillLeft ? "padding-left: 0;" : "")}
  ${(props) => (props.fillRight ? "padding-right: 0;" : "")}

  @media screen and (min-width: 576px) {
    padding: 0 40px;
    ${(props) => (props.fillLeft ? "padding-left: 0;" : "")}
    ${(props) => (props.fillRight ? "padding-right: 0;" : "")}
    ${(props) => (props.halfLeft ? "padding-right: 50vw;" : "")}
    ${(props) => (props.halfRight ? "padding-left: 50vw;" : "")}
  }

  @media screen and (min-width: 992px) {
    padding: 0 ${cssMapRange("1200px", "1920px", "7vw", "10vw")};
    ${(props) => (props.fillLeft ? "padding-left: 0;" : "")}
    ${(props) => (props.fillRight ? "padding-right: 0;" : "")}
    ${(props) => (props.halfLeft ? "padding-right: 50vw;" : "")}
    ${(props) => (props.halfRight ? "padding-left: 50vw;" : "")}
  }
`;

export default Container;
