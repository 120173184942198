import { useCallback, useEffect, useState } from "react";
import { mapRange } from "../utils/math";
import useMounted from "./useMounted";

const useMouseMove = () => {
  const mounted = useMounted();
  const [mouse, setMouseMove] = useState({ x: 0, y: 0 });

  const handleMouseMove = useCallback(
    (e) => {
      if (!mounted) return;
      const x = mapRange(e.clientX, 0, window.innerWidth, -1, 1);
      const y = mapRange(e.clientY, 0, window.innerHeight, -1, 1);
      setMouseMove({ x, y });
    },
    [mounted]
  );

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  return mouse;
};

export default useMouseMove;
