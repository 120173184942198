import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { ORANGE_COLOR } from "../style";

export interface InProgressProps {}

const InProgress: React.FC<InProgressProps> = (props) => {
  const fillStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    loop: true,
    config: {
      duration: 1000,
    },
  });

  const lineStyle = useSpring({
    from: { width: 0 },
    to: { width: 200 },
    delay: 1000,
  });

  return (
    <InProgressStyled>
      <Line style={lineStyle} />
      <Circle>
        <Fill style={fillStyle} />
      </Circle>
      <Text>In Progress</Text>
    </InProgressStyled>
  );
};

const InProgressStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
`;

const Line = styled(animated.div)`
  height: 1px;
  background: ${ORANGE_COLOR};
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${ORANGE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Fill = styled(animated.div)`
  width: 12px;
  height: 12px;
  border-radius: inherit;
  background: ${ORANGE_COLOR};
`;

const Text = styled.div`
  color: ${ORANGE_COLOR};
  font-weight: 500;
  margin-top: 1px;
  flex-shrink: 0;
`;

export default InProgress;
