import { useCallback, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AboutPage from "./AboutPage/AboutPage";
import "./App.css";
import CanvasBackground from "./CanvasBackground/CanvasBackground";
import CitationPage from "./CitationPage/CitationPage";
import ContactPage from "./ContactPage/ContactPage";
import Footer from "./Footer";
import LabPage from "./LabPage/LabPage";
import LandingPage from "./LandingPage/LandingPage";
import Preloader from "./Preloader/Preloader";
import ProjectsPage from "./ProjectsPage/ProjectsPage";
import RoutesWrapper from "./RoutesWrapper";
import ScrollController from "./ScrollController/ScrollController";
import TechnologiesPage from "./TechnologiesPage/TechnologiesPage";

function App() {
  const [loaded, setLoaded] = useState(false);

  const handleOnLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      {loaded ? (
        <Router>
          <ScrollController>
            <RoutesWrapper>
              <CanvasBackground />
              <LandingPage />
              <ProjectsPage />
              <LabPage />
              <TechnologiesPage />
              <AboutPage />
              <CitationPage />
              <ContactPage />
              <Footer />
            </RoutesWrapper>
          </ScrollController>
        </Router>
      ) : null}
      <Preloader onLoad={handleOnLoad} />
    </>
  );
}

export default App;
