import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import scrolled from "../ScrollController/scrolled";

export interface TechnologiesPageBackgroundProps {}

const TechnologiesPageBackground: React.FC<TechnologiesPageBackgroundProps> = (
  props
) => {
  const backgroundRef = useRef<HTMLDivElement>();

  const handleAction = useCallback(() => {
    const $bg = backgroundRef.current!;
    $bg.style["opacity"] = "1";
  }, []);

  const handleRevert = useCallback(() => {
    const $bg = backgroundRef.current!;
    $bg.style["opacity"] = "0";
  }, []);

  return (
    <TechnologiesPageBackgroundStyled
      ref={backgroundRef}
      action={handleAction}
      revert={handleRevert}
      offset={-window.innerHeight * 0.2}
      viewport="top"
    >
      <svg
        className="machine"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 645 526"
      >
        <g>
          <path
            x="-100,136"
            y="-100,136"
            className="small"
            d="M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z"
          />
        </g>
        <g>
          <path
            x="-173,694"
            y="-173,694"
            className="large"
            d="M635 184v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L592 58l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L472 0h-21l-4 29c-10 1-19 3-28 6L405 9l-19 8 7 28c-8 5-16 10-24 16l-23-17L331 58l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L635 184zM461 284c-61 0-110-49-110-110S401 64 461 64s110 49 110 110S522 284 461 284z"
          />
        </g>
        <g>
          <path
            x="-136,996"
            y="-136,996"
            className="medium"
            d="M392 390v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L342 313c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L392 390zM255 453c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C328 420 295 453 255 453z"
          />
        </g>
      </svg>
    </TechnologiesPageBackgroundStyled>
  );
};

const TechnologiesPageBackgroundStyled = styled(scrolled.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  .machine {
    position: absolute;
    width: 40vmin;
    stroke: white;
    fill: white;
    opacity: 0.025;
    right: 40px;
    bottom: 40px;
  }

  .small {
    -webkit-animation: counter-rotation 12.5s infinite linear;
    -moz-animation: counter-rotation 12.5s infinite linear;
    -o-animation: counter-rotation 12.5s infinite linear;
    animation: counter-rotation 12.5s infinite linear;
    -webkit-transform-origin: 100.136px 225.345px;
    -ms-transform-origin: 100.136px 225.345px;
    transform-origin: 100.136px 225.345px;
  }

  .medium {
    -webkit-animation: rotation 18.75s infinite linear;
    -moz-animation: rotation 18.75s infinite linear;
    -o-animation: rotation 18.75s infinite linear;
    animation: rotation 18.75s infinite linear;
    -webkit-transform-origin: 254.675px 379.447px;
    -ms-transform-origin: 254.675px 379.447px;
    transform-origin: 254.675px 379.447px;
  }

  .large {
    -webkit-animation: counter-rotation 25s infinite linear;
    -moz-animation: counter-rotation 25s infinite linear;
    -o-animation: counter-rotation 25s infinite linear;
    animation: counter-rotation 25s infinite linear;
    -webkit-transform-origin: 461.37px 173.694px;
    -ms-transform-origin: 461.37px 173.694px;
    transform-origin: 461.37px 173.694px;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(359deg);
    }
  }
  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(359deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @-webkit-keyframes counter-rotation {
    from {
      -webkit-transform: rotate(359deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }
  @-moz-keyframes counter-rotation {
    from {
      -moz-transform: rotate(359deg);
    }
    to {
      -moz-transform: rotate(0deg);
    }
  }
  @-o-keyframes counter-rotation {
    from {
      -o-transform: rotate(359deg);
    }
    to {
      -o-transform: rotate(0deg);
    }
  }
  @keyframes counter-rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

export default TechnologiesPageBackground;
