import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import ScrollControllerContext from "./ScrollControllerContext";
import { ScrollControllerContextType, SetComponentParams } from "./types";

export interface ScrollControllerProps {}

const ScrollController: React.FC<ScrollControllerProps> = (props) => {
  const stateRef = useRef<Map<string, boolean>>(new Map());

  const hooks = useMemo(() => {
    return new Map();
  }, []);

  const setComponent = useCallback(
    (params: SetComponentParams) => {
      hooks.set(params.id, params);
    },
    [hooks]
  );

  const reached = useCallback((id: string, reached: boolean) => {
    if (stateRef.current.has(id) && stateRef.current.get(id) === reached) {
      return true;
    } else {
      stateRef.current.set(id, reached);
      return false;
    }
  }, []);

  const handleActions = useCallback(
    (initial: boolean = false) => {
      for (const hookId of Array.from(hooks.keys())) {
        if (!hooks.has(hookId)) continue;
        const hook = hooks.get(hookId);
        if (!hook) continue;
        if (!hook.element.current) continue;
        const rect = hook?.element.current.getBoundingClientRect();
        const reference = hook.hook === "top" ? rect.top : rect.bottom;
        const triggerLine = hook.viewport === "top" ? 0 : window.innerHeight;
        if (reference + hook.offset < triggerLine) {
          if (!reached(hookId, true)) {
            if (
              hook.direction === "down" &&
              typeof hook.action === "function"
            ) {
              hook.action(initial);
            }
            if (hook.direction === "up" && typeof hook.revert === "function") {
              hook.revert(initial);
            }
          }
        } else {
          if (!reached(hookId, false)) {
            if (hook.direction === "up" && typeof hook.action === "function") {
              hook.action(initial);
            }
            if (
              hook.direction === "down" &&
              typeof hook.revert === "function"
            ) {
              hook.revert(initial);
            }
          }
        }
      }
    },
    [hooks, reached]
  );

  const value: ScrollControllerContextType = useMemo(() => {
    return {
      hooks,
      update: (initial: boolean = true) => handleActions(initial),
      setComponent,
    };
  }, [handleActions, hooks, setComponent]);

  const handleDocumentScroll = useCallback(
    (e) => {
      handleActions();
    },
    [handleActions]
  );

  useEffect(() => {
    document.addEventListener("scroll", handleDocumentScroll, false);
    return () => {
      document.removeEventListener("scroll", handleDocumentScroll, false);
    };
  }, [handleDocumentScroll]);

  useLayoutEffect(() => {
    handleActions(true);
  }, [handleActions]);

  return (
    <ScrollControllerContext.Provider value={value}>
      {props.children}
    </ScrollControllerContext.Provider>
  );
};

export default ScrollController;
