import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Canvas from "../Canvas/Canvas";
import ClimbScene from "../Canvas/ClimbScene";
import HelloScene from "../Canvas/HelloScene";
import useCanvasStore from "../Canvas/useCanvasStore";

export interface CanvasBackgroundProps {}

const CanvasBackground: React.FC<CanvasBackgroundProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let canvas: Canvas;
    if (ref.current) {
      canvas = new Canvas(ref.current);
      useCanvasStore.setState({ canvas });
      const climbScene = new ClimbScene();
      const helloScene = new HelloScene();
      canvas.addScene("climb", climbScene);
      canvas.addScene("hello", helloScene);
      canvas.enterScene("climb");
      canvas.render();
    }
    return () => {
      if (canvas) canvas.dispose();
    };
  }, []);

  return <CanvasBackgroundStyled ref={ref}></CanvasBackgroundStyled>;
};

const CanvasBackgroundStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  canvas {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export default CanvasBackground;
