import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { isMesh } from "../utils/threeJsUtils";
import Canvas from "./Canvas";

const loader = new GLTFLoader();

abstract class AbstractScene {
  scene: THREE.Scene;
  width: number = 0;
  height: number = 0;
  mouseX: number = 0;
  mouseY: number = 0;
  camera!: THREE.PerspectiveCamera;
  opacity: number = 1;
  currentOpacity: number = 1;

  constructor() {
    this.scene = new THREE.Scene();
    this.createCamera();
    this.render = this.render.bind(this);
  }

  abstract animate(context: Canvas, delta: number);

  loadObject(url: string) {
    return new Promise<GLTF>((resolve, reject) => {
      loader.load(url, resolve, undefined, reject);
    });
  }

  getAnimationClips(obj: GLTF, mixer: THREE.AnimationMixer) {
    return obj.animations.reduce((result, animation) => {
      result[animation.name] = mixer.clipAction(animation);
      return result;
    }, {});
  }

  createCamera() {
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      0.01,
      1000
    );
    this.camera.lookAt(0, 0, 0);
  }

  resizeCamera() {
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  restore() {}

  render(context: Canvas) {
    this.resizeCamera();
    this.animate(context, context.clock.getDelta());
    this.scene.traverse((object) => {
      if (isMesh(object)) {
        if (Array.isArray(object.material)) {
          object.material.forEach((material) => {
            material.transparent = true;
            material.opacity = this.currentOpacity;
          });
        } else {
          object.material.transparent = true;
          object.material.opacity = this.currentOpacity;
        }
      }
    });
    context.renderer!.render(this.scene, this.camera);
  }
}

export default AbstractScene;
