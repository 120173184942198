import * as THREE from "three";
import { Clock, MeshLambertMaterial } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { mapRange } from "../utils/math";
import { getFirstMaterial, isMesh } from "../utils/threeJsUtils";
import AbstractScene from "./AbstractScene";
import Canvas from "./Canvas";

type PyramidMeshData = {
  position: [number, number, number];
  color: [number, number, number];
};

const tmpVector3 = new THREE.Vector3();

class ClimbScene extends AbstractScene {
  stickman: GLTF | undefined;
  stickmanMixer: THREE.AnimationMixer | undefined;
  animations: Record<string, THREE.AnimationAction> = {};
  rootGroup: THREE.Group;
  pyramid!: THREE.InstancedMesh<THREE.BoxGeometry, THREE.MeshLambertMaterial>;
  clock: THREE.Clock;
  currentRotation: THREE.Vector3 = new THREE.Vector3(0, 0, 0);

  constructor() {
    super();
    this.handleLoop = this.handleLoop.bind(this);

    this.clock = new Clock(false);
    this.rootGroup = new THREE.Group();
    this.scene.add(this.rootGroup);

    this.camera.position.set(-2, 5, 15);
    this.camera.rotation.set(0, 0, 0);

    this.init();
  }

  async init() {
    this.createLights();
    this.createPyramid();
    await this.loadStickMan();
    this.rootGroup.add(this.pyramid);
    this.rootGroup.add(this.stickman!.scene);
    this.stickmanMixer?.addEventListener("loop", this.handleLoop);
    const climbAnimation = this.animations["climb"];
    if (climbAnimation) climbAnimation.play();
    this.clock.start();
  }

  createLights() {
    const ambientLight = new THREE.AmbientLight();
    ambientLight.intensity = 1.3;
    const pointLight = new THREE.PointLight();
    pointLight.intensity = 3.5;
    pointLight.position.set(0, 50, 15);
    this.scene.add(ambientLight, pointLight);
  }

  createPyramid() {
    const tmpMatrix4 = new THREE.Matrix4();
    const meshesData = this.createMeshesData();
    const colors = this.createColorsArray(meshesData);
    this.pyramid = this.createMesh(meshesData.length, colors);
    this.scene.add(this.pyramid);
    this.pyramid.setMatrixAt(0, tmpMatrix4);
    for (let i = 0; i < meshesData.length; i++) {
      const meshData = meshesData[i];
      tmpMatrix4.setPosition(...meshData.position);
      this.pyramid.setMatrixAt(i, tmpMatrix4);
    }
  }

  createMeshesData() {
    const data: PyramidMeshData[] = [];
    for (let level = 0; level < PYRAMID_LEVELS; level++) {
      const offset = level * PYRAMID_LEVEL_OFFSET;
      // Left side
      if (level < PYRAMID_LEVELS - 1) {
        data.push({
          position: [offset + PYRAMID_LEVEL_OFFSET, level, -1],
          color:
            level % 4 !== 0
              ? [1000, 127, 0].map((c) => c / 1000)
              : ([72, 72, 255].map((c) => c / 1000) as any),
        });
      }
      // Root and right side
      for (
        let z = 0;
        z < Math.min(PYRAMID_LEVELS - level, PYRAMID_MAX_SIDE);
        z++
      ) {
        data.push({
          position: [z / 2 + offset, level, z],
          color:
            (z + level) % 4 !== 0
              ? [1000, 127, 0].map((c) => c / 1000)
              : ([72, 72, 255].map((c) => c / 1000) as any),
        });
      }
    }
    return data;
  }

  createColorsArray(meshes: PyramidMeshData[]) {
    return Float32Array.from(
      meshes.flatMap((mesh) => {
        return mesh.color;
      })
    );
  }

  createMesh(count: number, colors: ArrayLike<number>) {
    const geometry = new THREE.BoxBufferGeometry(1, 1, 1);
    const material = new MeshLambertMaterial({ vertexColors: true });
    const colorBufferAttribute = new THREE.InstancedBufferAttribute(colors, 3);
    geometry.setAttribute("color", colorBufferAttribute);
    const mesh = new THREE.InstancedMesh(geometry, material, count);
    return mesh;
  }

  async loadStickMan() {
    this.stickman = await this.loadObject("/obj/climb.glb");
    this.stickman.scene.rotation.y = Math.PI / 2;
    this.stickman.scene.position.x = STICKYMAN_INITIAL_X;
    this.stickman.scene.position.y = STICKYMAN_INITIAL_Y;
    // Replace material to support opacity
    this.stickman.scene.traverse((object) => {
      if (isMesh(object)) {
        object.material = new THREE.MeshLambertMaterial({
          color: getFirstMaterial<THREE.MeshStandardMaterial>(object)?.color,
        });
      }
    });
    this.scene.add(this.stickman.scene);
    this.createStickManAnimation();
  }

  createStickManAnimation() {
    const stickman = this.stickman!;
    this.stickmanMixer = new THREE.AnimationMixer(stickman.scene);
    this.animations = this.getAnimationClips(stickman, this.stickmanMixer);
  }

  getElapsedLoops() {
    const climbAnimationDuration = this.stickman?.animations[0].duration!;
    const elapsedTime = this.clock.getElapsedTime();
    const loops = elapsedTime / climbAnimationDuration;
    return loops;
  }

  handleLoop() {
    const stickmanScene = this.stickman!.scene!;
    stickmanScene.position.x += STICKYMAN_OFFSET_PER_DURATION_X;
    stickmanScene.position.y += STICKYMAN_OFFSET_PER_DURATION_Y;
  }

  recalculateStickManPosition() {
    const loops = Math.floor(this.getElapsedLoops());
    const stickmanScene = this.stickman?.scene!;
    if (stickmanScene) {
      stickmanScene.position.x =
        STICKYMAN_INITIAL_X + STICKYMAN_OFFSET_PER_DURATION_X * loops;
      stickmanScene.position.y =
        STICKYMAN_INITIAL_Y + STICKYMAN_OFFSET_PER_DURATION_Y * loops;
    }
  }

  restore() {
    this.recalculateStickManPosition();
  }

  animate(ctx: Canvas, delta: number) {
    // Handle camera
    let x = -2;
    let y = 5;
    let z = 15;
    const highAspect = 1.55; // Full HD
    const lowAspect = 0.5; // 320x640
    const aspect = this.width / this.height;
    x = mapRange(aspect, lowAspect, highAspect, -2, -10);
    this.camera.position.set(x, y, z);

    const targetY = mapRange(this.mouseX, -1, 1, -0.05, 0.05);
    const targetX = mapRange(this.mouseY, -1, 1, -0.01, 0.01);
    this.camera.rotation.y += (targetY - this.camera.rotation.y) * 0.005;
    this.camera.rotation.x += (targetX - this.camera.rotation.x) * 0.01;

    // Handle scene animation
    if (this.stickmanMixer) {
      this.stickmanMixer?.update(delta);
    }
    const loops = this.getElapsedLoops();
    this.rootGroup.position.y = -loops * STICKYMAN_OFFSET_PER_DURATION_Y;
    this.rootGroup.position.x = -loops * STICKYMAN_OFFSET_PER_DURATION_X;
    const worldPosition = tmpVector3;
    this.pyramid.getWorldPosition(worldPosition);
    if (worldPosition.y <= -PYRAMID_LOOP_AT) {
      this.pyramid.position.y += 1 * PYRAMID_LOOP_BACK;
      this.pyramid.position.x += 0.5 * PYRAMID_LOOP_BACK;
    }
  }
}

const STICKYMAN_OFFSET_PER_DURATION_X = 1;
const STICKYMAN_OFFSET_PER_DURATION_Y = 2;
const STICKYMAN_INITIAL_X = -0.85;
const STICKYMAN_INITIAL_Y = -0.5;
const PYRAMID_LEVELS = 40;
const PYRAMID_LEVEL_OFFSET = 0.5;
const PYRAMID_MAX_SIDE = 10;
const PYRAMID_LOOP_AT = 15;
const PYRAMID_LOOP_BACK = 4;

export default ClimbScene;
