import React from "react";
import styled from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

type StyledProps = LayoutProps & ColorProps & SpaceProps & TypographyProps;

export interface ParagraphProps extends StyledProps {}

const Paragraph: React.FC<ParagraphProps> = ({ children, ...props }) => {
  return <ParagraphStyled {...(props as any)}>{children}</ParagraphStyled>;
};

const ParagraphStyled = styled.p<StyledProps>`
  font-size: 14px;
  line-height: 1.5;
  ${color}
  ${layout}
  ${space}
  ${typography}
`;

export default Paragraph;
