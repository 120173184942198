import { useEffect, useRef } from "react";

const useSecondEffect = (
  callback: React.EffectCallback,
  deps: React.DependencyList
) => {
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useSecondEffect;
